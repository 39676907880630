import React from "react"
import Image from "../components/image"
import NavBar from "../components/navbar"
import SEO from "../components/seo"


import "./mystyles.scss"
class IndexPage extends React.Component {
  render() {
    return (<section className="hero is-black is-fullheight">
      <SEO
        title="Home"
        keywords={[`3D`, `Visualisaties`, `Projecten`, `Animaties`]}
      />
      <NavBar />
      <div className="hero-body">
        <div className="container">
          <Image />
        </div>
        </div>
      </section>
    )
  }
}
export default IndexPage
